@font-face {
  font-family: "ctg bold";
  src: url("./assets/fonts/ctgsansb-webfont.woff2") format("woff2"),
    url("./assets/fonts/ctgsansb-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ctg light";
  src: url("./assets/fonts/ctgsansl-webfont.woff2") format("woff2"),
    url("./assets/fonts/ctgsansl-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ctg regular";
  src: url("./assets/fonts/ctgsansr-webfont.woff2") format("woff2"),
    url("./assets/fonts/ctgsansr-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserrat light";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserrat regular";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserrat bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserrat semibold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserrat medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: "montserrat regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
